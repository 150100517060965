<template>
  <div class="page">
    <Header />

    <div class = "infoblock">
      <h1>Bring Your Business To The Digital World</h1>
      <div>Many of your customers are searching the internet and if you don't have a website, how will they find you?  We specialize in small business website design, SEO, and digital marketing to put your name infront of your customers</div>
      
      <a href = "tel:8103283419">Call or Text: 810.328.3419</a>
      <router-link to="/Appointment">Request An Appointment</router-link>
    </div>

    <div class = "infoblock">
      <h1>Michigan Locals - USA Made</h1>
      <div>We're locals from Michigan and we do the work ourselves.  Nothing outsourced out of the US.  Speak to a US citizen every time you call.</div>
    </div>

    <div class = "services">
      <div class = "serviceblock thinborder" :class="{ selected : showid == 0 }" @click="showid = 0">
        <h1>Web Design Packages</h1>
        <div>Whether you are just starting out and don't yet have a website design in mind or already have a website and want to make it stand out from the competition, we have solutions for you.</div>
      </div>
      <div class = "serviceblock thinborder" :class="{ selected : showid == 1 }" @click="showid = 1">
        <h1>SEO Service Packages</h1>
        <div>It's not enough to be added to Google, you want to stand out above all of your competitors when someone searches for what your business offers.  With (S)earch (E)ngine (O)ptimization, we aim to do that through a website audit, keyword research, crawler management, sitemap submissions and more.</div>
      </div>
      <div class = "serviceblock thinborder" :class="{ selected : showid == 2 }" @click="showid = 2">
        <h1>Digital Marketing Management</h1>
        <div>We specialize in small business Digital Marketing, promoting businesses locally with limited budgets.  We manage your advertising across all the major search engines and social media based on what works best for promoting your company.</div>
      </div>
      <div class = "serviceblock thinborder" :class="{ selected : showid == 3 }" @click="showid = 3">
        <h1>Social Media Management</h1>
        <div>Creating engaging content that increases your online presence is a task that requires bandwidth that you may or may not have available with your current staff.  Instead of stressing them out with more work, why not let professionals handle your networking for you so you can focus on generating revenue?</div>
      </div>
    </div>

    <div class = "prop" v-bind:class="{ hidden : !showlearn }">
      <div class = "infoblock" v-if="showid == 0">
        <h1>Your Website Is  The First Way Most Of Your Customers See You</h1>
        <div class = "inputtitle">Think of your website as a basket of bananas in a grocery store. If you go shopping for bananas and you notice the sign for them is broken and the basket is barely holding together, you might think twice about buying any bananas, right?  That's what customers are thinking when they land on your slow, clunky website too.</div>
        <div class = "inputtitle">Or even worse, imagine wanting bananas and there is no basket!  Without a website, those customers may not even know that you exist!</div>
        <div class = "inputtitle">What we offer is a way to make sure that customers not only know that you have bananas, but that your bananas look amazing (Of course, bananas are whatever you are selling!)  If that sounds like something you would like, you can get the ball rolling today by getting a free proposal:</div>
        
        <a href = "tel:8103283419">Call or Text: 810.328.3419</a>
        <router-link to="/Appointment">Request An Appointment</router-link>
      </div>
      <div class = "infoblock" v-if="showid == 1">
        <h1>Search Engine Optimization Puts You Ahead Of Your Competition</h1>
        <div class = "inputtitle">Having a website is an important first step on creating your digital footprint in your market.  The next step is making sure that you can be found on search engines.</div>
        <div class = "inputtitle">To do that, you need to have webpages and content that drive keywords about your business.  With SEO Optimization, we do the heavy lifting of researching and finding what is good to make your business stand out and then create the content that makes you pop!</div>
        <div class = "inputtitle">Starting with a website audit, we'll analyze and come up with a game plan tailored just for you.  Waiting won't get you ranked higher, but we can.  Get started now:</div>

        <a href = "tel:8103283419">Call or Text: 810.328.3419</a>
        <router-link to="/Appointment">Request An Appointment</router-link>
      </div>
      <div class = "infoblock" v-if="showid == 2">
        <h1>Digital Marketing Management</h1>
        <div class = "inputtitle">There is a reason that so many companies are dumping hundreds of thousands (if not billions) of dollars into online ads.  It's because they work.</div>
        <div class = "inputtitle">When you are just starting out, or when you are trying to increase your sales leads, digital marketing puts you infront of more local people faster than any other medium.  And even more importantly, it's targeted, not random chance like print ads or TV commercials.</div>
        <div class = "inputtitle">We offer a complete hands off (or on if you'd prefer) digital marketing management solution.  We find which marketplaces work best for you and turn your ad spend into sales leads.  If you want to stop dumping money for print ads and start advertising in the digital world, start here:</div>
      
        <a href = "tel:8103283419">Call or Text: 810.328.3419</a>
        <router-link to="/Appointment">Request An Appointment</router-link>
      </div>
      <div class = "infoblock" v-if="showid == 3">
        <h1>Social Media Management</h1>
        <div class = "inputtitle">Building connections outside of shopping experiences is important for your business to thrive, and social media gives that opportunity on many different fronts.</div>
        <div class = "inputtitle">When customers are searching their feeds, the more things they connect with from you, the more they will have your name in front of them when thinking about their next purchase.</div>
        <div class = "inputtitle">From making and managing your social media accounts, to creating engaging content that drives more people towards your business we can handle it all, and you can get started here:</div>
      
        <a href = "tel:8103283419">Call or Text: 810.328.3419</a>
        <router-link to="/Appointment">Request An Appointment</router-link>
      </div>
    </div>

    <div class = "infoblock">
      <h1>Reactive Websites Designed For Your Customers</h1>
      <div>Every business has unique needs and customers.  We build your site from the ground up for you and your customers.</div>
    </div>
    <div class = "infoblock">
      <h1>Affordable Website Hosting - Free SSL Certificates</h1>
      <div>We offer website hosting plans as low as $10 a month, with excellent speeds and hardly any downtime.  And don't worry about your SSL Certificate, that is on us!</div>
    </div>
    <div class = "infoblock">
      <h1>Having Technical Issues?</h1>
      <div>Whether your website isn't loading, your computer isn't turning on, or you need help securing your sensitive files we provide services that help get your business running and stay running.  Give us a call or send us an email and let us know what you're having issues with!</div>
      
      <a href = "tel:8103283419">Call or Text: 810.328.3419</a>
      <router-link to="/Appointment">Request An Appointment</router-link>
    </div>

      <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'BusinessServices',
  components:{
      Header,
      Footer
  },
  data: function(){
    return{
      showid : 0
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a{
    display: block;
    color: #2c3e50;
    text-decoration: none;
    margin-top: 30px;
    border-radius: 2px;
    padding: 20px;
    background-color: rgb(105, 236, 214);
    width: 20%;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    transition: ease-in-out 0.2s;
    font-weight: bold;
}

a:hover{
    background-color: rgb(155, 187, 235);
}

.page{
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: #e6e6e6;
    position: absolute;
    width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
}

.services{
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px auto 10px auto;
  padding: 10px;
}

.infoblock{
  width: 80%;
  margin: auto;
}

.serviceblock{
  width: 20%;
  padding: 20px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.serviceblock:hover{
  background-color: rgb(155, 187, 235);
}

.selected{
  background-color: rgb(105, 236, 214);
}

.thinborder{
  border: solid 1px darkslategrey;
  border-radius: 13px;
}
</style>